<template>
<div class="forget-main">
  <div style="width: 600px; padding-top: 40px">
    <el-page-header @back="$router.back()" content="修改密码" >
    </el-page-header>
    <el-divider></el-divider>
  </div>
 
  <el-steps :active="active" finish-status="success" simple class="step-nav">
    <el-step title="1 设置新密码"></el-step>
    <el-step title="2 完成"></el-step>
  </el-steps>
  <el-form v-if="active == 1" label-position="left" label-width="120px" :rules="rules" ref="ruleForm" class="form" :model="ruleForm">
    <p style="color: #f56c6c; margin-bottom: 20px;font-size: 14px;">温馨提示：出于安全原因，需要您修改密码，方可进行后续使用。</p>
    <el-form-item label="设置新密码：" prop="password">
      <el-input v-model="ruleForm.password" placeholder="请输入6-20位，可设置字母、数字、特殊符号" show-password></el-input>
    </el-form-item>
    <el-form-item label="确定新密码：" prop="re_password">
      <el-input v-model="ruleForm.re_password" placeholder="请再次输入新密码" show-password></el-input>
    </el-form-item>
    <el-form-item>
      <el-button style="width: 200px" type="primary" @click="submitForm" :loading="submitLoading" :disabled="canSubmit">下一步</el-button>
    </el-form-item>
  </el-form>
  <el-result v-if="active == 2" icon="success" title="设置成功" subTitle="请牢记新的密码">
    <template slot="extra">
      <el-button type="primary" size="medium" @click="login">去登录</el-button>
    </template>
  </el-result>
</div>
</template>

<script>
export default {
  name: "forget",
  computed: {
    canSubmit() {
      return !(this.ruleForm.password && this.ruleForm.re_password);
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (this.ruleForm.re_password !== "") {
        this.$refs.ruleForm.validateField("re_password");
      }
      callback();
    };
    var validatePass2 = (rule, value, callback) => {
      console.log(value, this.ruleForm.password);
      if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      submitLoading: false,
      active: 1,
      ruleForm: {
        password: '',
        re_password: ''
      },
      rules: {
        password: [{
            required: true,
            message: "请输入密码",
            trigger: "blur"
          },
          {
            validator: validatePass,
            trigger: "blur"
          },
        ],
        re_password: [{
            required: true,
            message: "请再次输入密码",
            trigger: "blur"
          },
          {
            validator: validatePass2,
            trigger: "blur"
          },
        ],
      },
    };
  },
  mounted() {},
  components: {},
  methods: {
    login() {
      this.$router.push("/user/login")
    },
    logout(e) {
      console.log("logout", e);
      localStorage.clear();
      // this.$router.push("/user/login");
    },
    async submitForm() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          this.submitLoading = true;
          let res = await this.$api({
            method: "post",
            url: "/api/resetpswd",
            data: {
              newpswd: this.ruleForm.password,
              repswd: this.ruleForm.re_password,
            }
          });
          this.submitLoading = false;
          if (res.code == 0) {
            this.active = 2;
            this.logout();
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
  },
};
</script>

<style lang="scss">
.forget-main {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .step-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
    width: 500px;
    padding: 20px 50px;
  }

  .step-nav_item {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .step-nav_active {
    color: red;
  }

  .form {
    width: 500px;
  }

  .sms-btn {
    width: 100px;
    flex: 0 0 100px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
  }
}
</style>

<style lang="scss">
.forget-main {
  .el-form-item__content {
    display: flex;
  }

  .el-step {
    display: flex;
    justify-content: center;
  }

  .el-step.is-simple .el-step__main {
    flex: auto;
  }

  .el-form-item {
    margin-bottom: 32px;
  }
}
</style>
